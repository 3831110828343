const rules = [
  {
    id: 1,
    status: true,
    icon: 'check-circle-fill',
    image: [
      {
        id: 1,
        url:
            'https://storage.googleapis.com/komerce/assets/illustration/photo-selfie-ktp.jpg',
        width: 100,
      },
      {
        id: 2,
        url:
            'https://storage.googleapis.com/komerce/assets/illustration/sample-ktp.jpg',
        width: 100,
      },
    ],
    bodyClass: 'bg-[#DCF3EB] rounded-md',
    classStatus: 'text-emerald-700 ml-1',
    classTitle: 'text-emerald-700 font-medium mt-1',
    classLabel: 'font-normal text-emerald-600',
    variant: 'success',
    title: 'Status verifikasi KTP diterima apabila :',
    main_rules: [
      { id: 1, label: 'Selfie + KTP terfoto dengan jelas dan tidak terpotong' },
      {
        id: 2,
        label: 'Data yang ada di profile sesuai dengan data yang ada di KTP',
      },
    ],
  },
  {
    id: 2,
    status: false,
    icon: 'x-circle-fill',
    image: [
      {
        id: 1,
        url:
            'https://storage.googleapis.com/komerce/assets/illustration/photo-selfie-ktp-2.jpg',
        width: 100,
      },
      {
        id: 2,
        url:
            'https://storage.googleapis.com/komerce/assets/illustration/sample-ktp-blur.jpg',
        width: 100,
      },
    ],
    bodyClass: 'bg-[#FFEDED] rounded-md',
    classStatus: 'text-rose-700 ml-1',
    classTitle: 'text-rose-700 font-medium mt-1',
    classLabel: 'font-normal text-rose-600',
    variant: 'danger',
    title: 'Status verifikasi KTP ditolak apabila :',
    main_rules: [
      { id: 1, label: 'Selfie + KTP terfoto tidak jelas dan terpotong' },
      {
        id: 2,
        label:
            'Data yang ada di profile tidak sesuai dengan data yang ada di KTP',
      },
    ],
  },
]

export default rules
