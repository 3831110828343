<template>
  <BCard>
    <div class="flex flex-row justify-between items-center">
      <div class="text-black text-2xl font-medium">
        Verifikasi Data Diri
      </div>
      <div
        v-if="data !== null"
        class="flex items-center gap-4"
      >
        <div v-if="data.status !== 'not requested'">
          {{ DAY_MONTH_YEAR(requestDate) }} | {{ TIME(requestDate) }}
        </div>
        <BButton
          variant="primary"
          size="small"
          style="border-radius: 25px !important"
          @click="$bvModal.show('modal-rules')"
        >
          Petunjuk Verifikasi
          <BIcon icon="question-circle" />
        </BButton>
      </div>
    </div>
    <BOverlay
      :show="loading"
      spinner-variant="primary"
      variant="light"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <ValidationObserver #default="{ invalid }">
        <BRow>
          <BCol lg="6">
            <BCard body-class="flex flex-col gap-3">
              <div>
                <div class="text-black text-xl font-medium">
                  Foto wajah beserta KTP kamu
                  <span
                    v-if="data.status !== 'not requested'"
                    :class="getClassStatus(status.selfie)"
                  >
                    ({{ getLabelStatus(status.selfie) }})
                  </span>
                </div>
                <div class="text-[#828282] text-base">
                  Unggah foto diri beserta KTP kamu
                </div>
              </div>
              <img
                :src="handlePreviewImage('selfie', imageUrl.selfie)"
                alt="Komerce"
                class="rounded-xl"
              >
              <span v-if="checkFormSelfie">
                <div class="text-sm text-center mb-1">
                  Max. 1Mb
                </div>
                <ValidationProvider
                  #default="{ errors }"
                  :rules="{ required: checkFormSelfie ? true : false }"
                >
                  <BFormFile
                    v-model="preview.selfie"
                    :state="errors.length > 0 ? false : null"
                    browse-text="Pilih file"
                    :placeholder="
                      name.selfie ? name.selfie : 'belum ada file yang dipilih'
                    "
                    @change="onUploadFile('selfie', $event)"
                  />
                  <BFormTextarea
                    v-if="status.selfie === 'rejected'"
                    v-model="notes.selfie"
                    class="mt-1"
                    disabled
                    rows="4"
                  />
                  <div
                    v-if="allowFileSize.selfie"
                    class="flex items-center gap-2 mt-1"
                  >
                    <img
                      src="https://storage.googleapis.com/komerce/assets/icons/danger-red.svg"
                      alt="Komerce"
                      width="15"
                    >
                    <small class="text-primary">Ukuran file terlalu besar</small>
                  </div>
                </ValidationProvider>
              </span>
            </BCard>
          </BCol>
          <BCol lg="6">
            <BCard body-class="flex flex-col gap-3">
              <div>
                <div class="text-black text-xl font-medium">
                  Foto KTP kamu
                  <span
                    v-if="data.status !== 'not requested'"
                    :class="getClassStatus(status.idCard)"
                  >
                    ({{ getLabelStatus(status.idCard) }})
                  </span>
                </div>
                <div class="text-[#828282] text-base">
                  Unggah foto KTP kamu
                </div>
              </div>
              <img
                :src="handlePreviewImage('idCard', imageUrl.idCard)"
                alt="Komerce"
                class="rounded-xl"
              >
              <span v-if="checkFormIdCard">
                <div class="text-sm text-center mb-1">
                  Max. 1Mb
                </div>
                <ValidationProvider
                  #default="{ errors }"
                  :rules="{ required: checkFormIdCard ? true : false }"
                >
                  <BFormFile
                    v-model="preview.idCard"
                    :state="errors.length > 0 ? false : null"
                    browse-text="Pilih file"
                    :placeholder="
                      name.idCard ? name.idCard : 'belum ada file yang dipilih'
                    "
                    @change="onUploadFile('idCard', $event)"
                  />
                  <BFormTextarea
                    v-if="status.idCard === 'rejected'"
                    v-model="notes.idCard"
                    class="mt-1"
                    disabled
                    rows="4"
                  />
                  <div
                    v-if="allowFileSize.idCard"
                    class="flex items-center gap-2 mt-1"
                  >
                    <img
                      src="https://storage.googleapis.com/komerce/assets/icons/danger-red.svg"
                      alt="Komerce"
                      width="15"
                    >
                    <small class="text-primary">Ukuran file terlalu besar</small>
                  </div>
                </ValidationProvider>
              </span>
            </BCard>
          </BCol>
        </BRow>
        <BRow>
          <BCol lg="12">
            <div class="p-1">
              <div class="flex flex-row items-start bg-[#DFF3FF] p-1 rounded-lg border-2 border-[#08A0F7]">
                <img
                  src="https://storage.googleapis.com/komerce/assets/svg/info-circle.svg"
                  alt="Komerce"
                >
                <div class="ml-[10px]">
                  <span class="text-black font-bold text-lg">
                    Informasi Data Partner
                  </span>
                  <div class="text-black">
                    <span>Pastikan dokumen yang di-upload sama persis dengan informasi data partner yang akan mengajukan verifikasi identitas</span>
                  </div>
                </div>
              </div>
            </div>
          </BCol>
        </BRow>
        <BOverlay
          :show="loading"
          spinner-variant="primary"
          variant="light"
          blur="0"
          opacity=".5"
          rounded="sm"
        >
          <BRow class="justify-center items-center mt-3 gap-[10px]">
            <BCol
              lg="12"
              class="flex items-center"
            >
              <BCol
                lg="4"
                class="text-lg"
              >
                Pengguna Produk
              </BCol>
              <BCol
                lg="6"
                class="flex items-center"
              >
                <div
                  v-for="(product, key) in productList"
                  :key="key"
                >
                  <img
                    :src="product.url_image"
                    alt="Komerce"
                    width="30"
                  >
                </div>
              </BCol>
            </BCol>
            <BCol
              lg="12"
              class="flex items-center"
            >
              <BCol
                lg="4"
                class="text-lg"
              >
                <span>NIK</span>
                <span class="text-primary">*</span>
              </BCol>
              <BCol lg="6">
                <ValidationProvider
                  ref="nikInput"
                  #default="{ errors }"
                  name="NIK"
                  rules="required|min:16"
                >
                  <BFormInput
                    v-model="newNik"
                    :state="errors.length > 0 ? false : null"
                    :disabled="isDisabledIdentityNumber ? true : false"
                    maxlength="16"
                    placeholder="Masukkan NIK sesuai dengan foto"
                    @keypress="isNumber($event)"
                    @blur="checkNIK()"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </BCol>
            </BCol>
            <BCol
              lg="12"
              class="flex items-center"
            >
              <BCol
                lg="4"
                class="text-lg"
              >
                <span>Nama</span>
                <span class="text-primary">*</span>
                <div class="text-xs text-[#626262] w-[80%]">
                  Pastikan nama yang kamu masukkan sesuai dengan informasi yang ada pada file unggahan
                </div>
              </BCol>
              <BCol lg="6">
                <ValidationProvider
                  #default="{ errors }"
                  name="Nama"
                  rules="required|min:3|max:40"
                >
                  <BFormInput
                    v-model="kycInformation.name"
                    :state="errors.length > 0 ? false : null"
                    maxlength="40"
                    placeholder="Masukkan nama"
                    :disabled="profile.is_komcards === 1 || profile.verification_ktp_status === 2"
                    @keypress="isAlphabet($event)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </BCol>
            </BCol>
            <BCol
              lg="12"
              class="flex items-center"
            >
              <BCol
                lg="4"
                class="text-lg"
              >
                <span>Email</span>
                <span class="text-primary">*</span>
              </BCol>
              <BCol lg="6">
                <BInput
                  v-model="kycInformation.email"
                  disabled
                />
              </BCol>
            </BCol>
            <BCol
              lg="12"
              class="flex items-center"
            >
              <BCol
                lg="4"
                class="text-lg"
              >
                <span>No. Hp</span>
                <span class="text-primary">*</span>
              </BCol>
              <BCol lg="6">
                <BInput
                  v-model="kycInformation.phone"
                  disabled
                />
              </BCol>
            </BCol>
          </BRow>
        </BOverlay>
        <BRow
          :class="profile.is_komship === 1 ? 'justify-between' : 'justify-end'"
          class="m-2"
        >
          <div
            v-if="profile.is_komship === 1"
            class="mt-2"
          >
            <b-form-checkbox
              v-model="aggreementKomship"
              class="custom-control-primary mb-1"
            >
              Saya menyetujui kebijakan verifikasi identitas KTP. <a
                class="underline text-primary"
                href="https://komship.id/terms/"
                target="_blank"
              >Lihat detail</a>
            </b-form-checkbox>
          </div>
          <div class="px-2 mt-2">
            <BButton
              class="mr-2"
              variant="outline-primary"
              @click="validateBack()"
            >
              Kembali
            </BButton>
            <BButton
              type="submit"
              :variant="invalid ? 'secondary' : 'primary'"
              :disabled="invalid || disabled"
              @click="$bvModal.show(idModal)"
            >
              Ajukan Verifikasi
            </BButton>
          </div>
        </BRow>
      </ValidationObserver>
    </BOverlay>

    <ModalVerificationKyc
      :id-modal="idModal"
      :title="'Pengajuan Verifikasi KTP'"
      :desc="'Pastikan bahwa file KTP dan Selfie yang anda ajukan telah benar'"
      :source="'verif'"
      :loading="loadingSubmit"
      :submit="onSubmit"
    />
    <RulesVerification />
    <ModalConfirm
      id="modal-validation-out"
      title="Kamu Yakin Keluar?"
      message="Data yang sudah kamu inputkan akan hilang"
      label-confirm-button="Keluar"
      label-cancel-button="Batal"
      @on-click-cancel-button="$bvModal.hide('modal-validation-out')"
      @on-click-confirm-button="back()"
    />
  </BCard>
</template>

<script>
import { mapState } from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, min } from '@validations'
import { newAxiosIns } from '@/libs/axios'
import { DAY_MONTH_YEAR, TIME } from '@/libs/formatDate'
import { isNumber, isAlphabet } from '@/libs/helpers'
import ModalConfirm from '@/views/components/modal/ModalConfirm.vue'
import RulesVerification from './RulesVerification.vue'
import ModalVerificationKyc from '../../pages/komcard/components/ModalVerificationKyc.vue'
import { getLabelStatus, getClassStatus } from '../../pages/komcard/config'

export default {
  components: {
    ValidationObserver, ValidationProvider, RulesVerification, ModalVerificationKyc, ModalConfirm,
  },
  data() {
    return {
      getLabelStatus,
      getClassStatus,
      DAY_MONTH_YEAR,
      TIME,
      isNumber,
      isAlphabet,
      preview: {
        selfie: null,
        idCard: null,
      },
      name: {
        selfie: null,
        idCard: null,
      },
      allowFileSize: {
        selfie: false,
        idCard: false,
      },
      allowFileExt: {
        selfie: false,
        idCard: false,
      },
      aggreementKomship: false,
      size: {
        selfie: 0,
        idCard: 0,
      },
      required,
      min,
      loadingSubmit: false,
      loading: false,
      data: null,
      kycInformation: {},
      productList: [],
      imageUrl: {
        selfie: '',
        idCard: '',
      },
      status: {
        selfie: '',
        idCard: '',
      },
      notes: {
        selfie: '',
        idCard: '',
      },
      idModal: 'modal-confirm-kyc',
      requestDate: '',
      isBack: false,
      nextRoute: '',
      newNik: '',
    }
  },
  computed: {
    ...mapState('dashboard', ['profile']),
    checkFormSelfie() {
      const confitions = [
        this.status.selfie === 'rejected',
        this.data.status === 'not requested',
      ]
      return confitions.some(condition => condition)
    },
    checkFormIdCard() {
      const conditions = [
        this.status.idCard === 'rejected',
        this.data.status === 'not requested',
      ]
      return conditions.some(condition => condition)
    },
    disabled() {
      const conditions = [
        this.allowFileSize.selfie,
        this.allowFileSize.idCard,
        this.allowFileExt.idCard,
        this.allowFileExt.selfie,
        this.loadingSubmit,
      ]
      if (this.profile.is_komship === 1) conditions.push(!this.aggreementKomship)
      return conditions.some(condition => condition)
    },
    isDisabledIdentityNumber() {
      return ['approved', 'requested'].includes(this.data.status)
    },
  },
  mounted() {
    this.getFile()
    window.onbeforeunload = event => {
      event.preventDefault()
      return true
    }
  },
  beforeRouteLeave(to, from, next) {
    this.nextRoute = to.fullPath
    if (this.preview.selfie !== null || this.preview.idCard !== null || this.newNik !== null || this.kycInformation.name !== null) {
      this.$bvModal.show('modal-validation-out')
      next(this.isBack)
    } else {
      next(true)
    }
  },
  methods: {
    validateBack() {
      this.$bvModal.show('modal-validation-out')
    },
    back() {
      this.isBack = true
      this.$bvModal.hide('modal-validation-out')
      if (this.nextRoute === '') {
        this.$router.go(-1)
      } else {
        this.$router.push(this.nextRoute)
      }
    },
    async getFile() {
      this.loading = true
      const url = '/auth/api/v1/partner/request-verification-ktp/latest-history'
      await newAxiosIns.get(url)
        .then(res => {
          const { data } = res.data
          this.data = data
          this.requestDate = data.requested_date
          this.status.selfie = data.ktp_face_picture.status
          this.status.idCard = data.ktp_picture.status
          this.imageUrl.selfie = data.ktp_face_picture.url
          this.imageUrl.idCard = data.ktp_picture.url
          this.notes.selfie = data.ktp_face_picture.notes
          this.notes.idCard = data.ktp_picture.notes
          this.kycInformation = data.info_data_partner
          this.productList = data.product_partner
          this.newNik = this.kycInformation.nik
          this.loading = false
        })
        .catch(err => {
          const { response: { data: { data } } } = err
          this.data = data
          this.loading = false
        })
    },
    async onSubmit() {
      this.loadingSubmit = true
      const url = '/auth/api/v1/partner/request-verification-ktp'
      const formData = new FormData()

      if (this.status.selfie === 'approved') {
        formData.append('image_ktp_base_64', this.preview.idCard)
        formData.append('request_nik', this.newNik)
      } else if (this.status.idCard === 'approved') {
        formData.append('image_ktp_with_face_base_64', this.preview.selfie)
        formData.append('request_nik', this.newNik)
      } else {
        formData.append('image_ktp_base_64', this.preview.idCard)
        formData.append('image_ktp_with_face_base_64', this.preview.selfie)
        formData.append('request_nik', this.newNik)
      }

      if (this.profile.is_komship === 1) {
        formData.append('request_name', this.kycInformation.name)
      }
      await newAxiosIns
        .post(url, formData)
        .then(() => {
          this.loadingSubmit = false
          this.isBack = true
          this.$bvModal.hide(this.idModal)
          this.$toast_success({
            message: 'Berhasil mengajukan Verifikasi KTP. Silahkan tunggu konfirmasi lebih lanjut selama 1x24 jam',
          })
          this.$router.push('/setting-kompship/profile')
        })
        .catch(() => {
          this.loadingSubmit = false
          this.$toast_error({ message: 'Ada kesalahan saat mengajukan verifikasi KTP' })
        })
    },
    onUploadFile(type, event) {
      const { target } = event
      const file = target.files[0]
      const fileSize = file.size
      const fileType = file.type
      const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png']
      const isValidType = allowedTypes.includes(fileType)

      if (fileSize > 1024 * 1024) {
        this.allowFileSize[type] = true
      } else {
        this.allowFileSize[type] = false
      }

      if (!isValidType) {
        this.allowFileExt[type] = true
        this.$toast_error({ message: 'File yang kamu masukan harus berupa format image' })
      } else if (file) {
        this.allowFileExt[type] = false
        const reader = new FileReader()

        reader.onload = e => {
          this.preview[type] = e.target.result
        }

        reader.readAsDataURL(file)
      } else {
        this.preview[type] = null
      }
    },
    handlePreviewImage(type, image) {
      if (this.preview[type] !== null) { return this.preview[type] }
      if (this.data.status !== 'not requested') { return [image] }
      return 'https://storage.googleapis.com/komerce/assets/illustration/placeholder-selfie.svg'
    },
    checkNIK() {
      if (this.newNik.length === 16 && this.kycInformation.nik !== this.newNik) {
        const url = '/auth/api/v1/check-nik'
        this.$http_new
          .post(`${url}?nik=${this.newNik}&email=${this.kycInformation.email}`)
          .then(res => {
            this.$refs.nikInput.applyResult({
              errors: [],
              valid: true,
              failedRules: {},
            })
          })
          .catch(err => {
            if (err.response.data.meta.message === 'nik already inserted') {
              this.$refs.nikInput.applyResult({
                errors: ['Nomor KTP sudah digunakan'],
                valid: false,
                failedRules: {},
              })
            }
          })
      }
    },
  },
}
</script>
